/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Layout from "../components/layout"

const Privacy = () => (
  <Layout>
    <div
      css={{
        fontSize: 14,
        padding: 20,
        maxWidth: 800,
        whiteSpace: "initial",
        margin: "0 auto",
        h1: {
          fontSize: 30,
        },
        p: {
          fontWeight: 300,
          fontFamily: "Helvetica",
        },
        ul: {
          fontWeight: 300,
          fontFamily: "Helvetica",
        },
      }}
    >
      <h1>Privacy Policy</h1>
      <b>Last Updated: March 5, 2020</b>
      <br />
      <br />
      <p>
        Be Grizzlee, a division of Seamount, Inc. ("GRIZZLEE") respects your
        concerns about privacy. This privacy policy ("Privacy Policy") describes
        the types of personal information we collect about individuals on
        www.begrizzlee.com (the "Site"), how we may use the information, with
        whom we may share it, your rights and the choices available regarding
        our use of the information. The Privacy Policy also describes the
        measures we take to safeguard the personal information, how long we
        retain it and how individuals can contact us about our privacy practices
        and to exercise their rights.
      </p>
      <p> Click on one of the links below to jump to the listed section:</p>
      <ul>
        <li>
          <a href="#information-obtained">Information We Obtain</a>
        </li>
        <li>
          <a href="#information-use">How We Use the Information We Obtain</a>
        </li>
        <a href="#information-use"></a>
        <li>
          <a href="#information-use"></a>
          <a href="#data-collection">Automated Collection of Data</a>
        </li>
        <li>
          <a href="#information-sharing">Information Sharing</a>
        </li>
        <li>
          <a href="#data-transfers">Data Transfers</a>
        </li>
        <li>
          <a href="#rights-choices">Your Rights and Choices</a>
        </li>
        <li>
          <a href="#third-party">
            Other Online Services and Third-Party Features
          </a>
        </li>
        <li>
          <a href="#personal-info-retention">
            Retention of Personal Information
          </a>
        </li>
        <li>
          <a href="#personal-info-protection">
            How We Protect Personal Information
          </a>
        </li>
        <li>
          <a href="#childrens-personal-info">Children’s Personal Information</a>
        </li>
        <li>
          <a href="#updates">Updates to Our Privacy Policy</a>
        </li>
        <li>
          <a href="#contact">How to Contact Us</a>
        </li>
      </ul>
      <h3 id="information-obtained">Information We Obtain </h3>
      <p>
        In connection with your use of the Site, you provide personal
        information to us in various ways. The types of personal information we
        obtain include personal information contained in content you submit,
        such as when you email us by clicking on GRIZZLEE email address
        hyperlinks on the Site. You are not required to provide this information
        but, if you choose not to do so, we may not be able to offer you certain
        of our services and features of the Site.
      </p>
      <h3 id="information-use">How We Use the Information We Obtain</h3>
      <p>
        We use the information we obtain on the Site on the basis of our
        legitimate interests, which we would be glad to describe further upon
        request, including to:
      </p>
      <ul>
        <li>provide our services to you;</li>
        <li>market our services to you;</li>
        <li>respond to your requests and inquiries;</li>
        <li>
          send you newsletters and other communications if you sign up to
          receive them;
        </li>
        <li>
          operate, evaluate and improve our business (including developing new
          services; enhancing and improving our services; managing our
          communications; and performing accounting, auditing and other internal
          functions);
        </li>
        <li>
          protect against, identify and prevent fraud and other criminal
          activity, claims and other liabilities; and
        </li>
        <li>
          comply with and enforce applicable legal requirements, relevant
          industry standards and our policies, including this Privacy Policy,
          GRIZZLEE's Privacy Shield Policy, and GRIZZLEE's Terms of Service.
        </li>
      </ul>
      <p>
        We may also use the personal information we obtain on the Site to
        analyze our services, personalize your experience on the Site and
        perform data analytics and market research. As required by applicable
        law, we will obtain your consent to process personal information for
        these purposes. We also may use the information we obtain in other ways
        for which we provide specific notice at the time of collection.
      </p>
      <h3 id="data-collection">Automated Collection of Data</h3>
      <p>
        When you use the Site, we may obtain certain information by automated
        means, such as browser cookies and pixel tags. If we obtain such
        information in this manner, it could include your device IP address,
        domain name, identifiers associated with your devices, device and
        operating system type and characteristics, web browser characteristics,
        language preferences, clickstream data, your interactions with the Site
        (such as the web pages you visit, links you click and features you use),
        the pages that led or referred you to the Site, dates and times of
        access to the Site, and other information about your use of the Site.
        <br />
        <br />
        A “cookie” is a text file that websites send to a visitor’s computer or
        other Internet-connected device to uniquely identify the visitor’s
        browser or to store information or settings in the browser. This
        technology helps us (1) remember your information so you will not have
        to re-enter it; (2) track and understand how you use and interact with
        the Site; (3) tailor the Site around your preferences; (4) measure the
        usability of the Site; (5) understand the effectiveness of our
        communications; and (6) otherwise manage and enhance the Site.
        <br />
        <br />
        If we collect cookies, then to the extent required by applicable law, we
        will obtain your consent before placing non-essential cookies or similar
        technologies on your computer. You can stop certain types of cookies
        from being downloaded on your computer by selecting the appropriate
        settings on your web browser. Most web browsers will tell you how to
        stop accepting new browser cookies, how to be notified when you receive
        a new browser cookie and how to disable existing cookies. You can find
        out how to do this for your particular browser by clicking “help” on
        your browser’s menu or by visiting{" "}
        <a href="https://www.allaboutcookies.org" target="_blank">
          www.allaboutcookies.org
        </a>
        . The Site is not designed to respond to "do not track" signals received
        from browsers. Please note that, if we collect cookies or use other
        automated tool, then without such cookies or other automated tools, you
        may not be able to use all of the features of the Site.
        <br />
        <br />
        We do not obtain personal information about your online activities over
        time or across third-party websites, devices or other online services.
        On the Site, we may use third-party online analytics services, such as
        those of Google Analytics. If we use such third-party online analytics
        service, then to the extent required by applicable law, we will obtain
        your consent before using such third-party analytics cookies. If we use
        analytics services, then the service providers that administer these
        analytics services use automated technologies to collect data (such as
        email address, IP addresses, cookies and other device identifiers) to
        evaluate, for example, use of the Site and to diagnose technical issues.
        To learn more about Google Analytics and how to manage your preferences,
        please visit www.google.com/analytics/learn/privacy.html.
      </p>
      <h3 id="information-sharing">Information Sharing</h3>
      <p>
        We do not disclose personal information we obtain about you, except as
        described in this Privacy Policy. We share your personal information
        with our (1) subsidiaries and affiliates and (2) service providers who
        perform services on our behalf, including data analytics providers, for
        the purposes described in this Privacy Policy. We do not authorize our
        service providers to use or disclose the information except as necessary
        to perform services on our behalf or comply with legal requirements.
        <br />
        <br />
        We also may disclose information about you (1) if we are required to do
        so by law or legal process (such as a court order or subpoena); (2) in
        response to requests by government agencies, such as law enforcement
        authorities; (3) to establish, exercise or defend our legal rights; (4)
        when we believe disclosure is necessary or appropriate to prevent
        physical or other harm or financial loss; (5) in connection with an
        investigation of suspected or actual illegal activity; (6) in the event
        we sell or transfer all or a portion of our business or assets
        (including in the event of a reorganization, dissolution or
        liquidation); or (7) otherwise with your consent.
      </p>
      <h3 id="data-transfers">Data Transfers</h3>
      <p>
        We transfer the personal information we collect about you to recipients
        (including our subsidiaries and affiliates, and third-party service
        providers) in countries other than the country in which the information
        originally was collected, including the U.S. Those countries may not
        have the same data protection laws as the country in which you initially
        provided the information. When we transfer your information to
        recipients in these countries, we will protect that information as
        described in this Privacy Policy and will comply with applicable legal
        requirements to provide adequate protection for the transfer of personal
        information to recipients in countries other than the one in which you
        provided the information.
        <br />
        <br />
        If you are located in the European Economic Area ("EEA") or Switzerland,
        we will comply with applicable legal requirements providing adequate
        protection for the transfer of personal information to recipients in
        countries outside of the EEA and Switzerland. With respect to transfers
        of personal information to the U.S., GRIZZLEE is certified under the
        EU-U.S. and Swiss-U.S. Privacy Shield frameworks developed by the U.S.
        Department of Commerce and the European Commission and Swiss Federal
        Data Protection and Information Commissioner, respectively, regarding
        the transfer of personal information from the EEA or Switzerland to the
        U.S. Click here to view our EU-U.S. and Swiss-U.S. Privacy Shield
        Privacy Policy. Your information will also be transferred to our third
        party service providers in the U.S. We have implemented appropriate
        safeguards designed to ensure an adequate level of data protection,
        including by concluding data transfer agreements incorporating the
        European Commission’s Standard Contractual Clauses under Article 46 of
        the EU General Data Protection Regulation (GDPR), or by selecting U.S.
        service providers that are certified to the Privacy Shield frameworks.
        You may obtain a copy of these data transfer mechanisms by contacting us
        as indicated below.
      </p>
      <h3 id="rights-choices">Your Rights and Choices</h3>
      <p>
        We offer you certain choices in connection with the personal information
        we obtain about you. To update your preferences, update or correct your
        information, limit the communications you receive from us, or submit a
        request, please contact us as specified in the How to Contact Us section
        of this Privacy Policy. You also can unsubscribe from our marketing
        mailing lists by following the "Unsubscribe" link in our emails.
        <br />
        <br />
        To the extent provided by applicable law, you have the right to (1)
        request access to the personal information we maintain about you; (2)
        request that we update, correct, amend, erase or restrict the
        information; or (3) exercise your right to data portability, by
        contacting us as indicated below. Depending on your location, you may
        have the right to file a complaint with a government regulator if you
        are not satisfied with our response. If you are located in the EEA and
        are not satisfied with our responses, you may lodge a complaint with the
        data protection authority in your country of residence, place of work or
        place of the alleged infringement of the GDPR. Where provided by law,
        you may withdraw any consent you previously provided to us or object at
        any time to the processing of your personal information on legitimate
        grounds relating to your particular situation, and we will apply your
        preferences going forward. To help protect your privacy and maintain
        security, we may take steps to verify your identity before granting you
        access to your information. To the extent permitted by applicable law, a
        charge may apply before we provide you with a copy of any of your
        personal information that we maintain.
      </p>
      <h3 id="third-party">Other Online Services and Third-Party Features</h3>
      <p>
        The Site may provide links to other online services and websites for
        your convenience and information (e.g., Facebook, Vimeo Instagram,
        LinkedIn and Twitter buttons). These services, websites, and third-party
        features may operate independently from us. The privacy practices of
        these third parties, including details on the information they may
        collect about you, are subject to the privacy statements of these
        parties, which we strongly suggest you review. To the extent any linked
        online services or third-party features are not owned or controlled by
        us, GRIZZLEE is not responsible for these third parties’ information
        practices.
      </p>
      <h3 id="personal-info-retention">Retention of Personal Information</h3>
      <p>
        To the extent permitted by applicable law, we retain personal
        information we obtain about you for the duration of our relationship
        with you, plus a reasonable period to be able to run regular deletion
        routines, to take into account the applicable statute of limitations
        period or to comply with mandatory applicable law.
      </p>
      <h3 id="personal-info-protection">How We Protect Personal Information</h3>
      <p>
        We maintain administrative, technical and physical safeguards designed
        to protect personal information we obtain through the Site against
        accidental, unlawful or unauthorized destruction, loss, alteration,
        access, disclosure or use.
      </p>
      <h3 id="childrens-personal-info">Children’s Personal Information</h3>
      <p>
        The Site is designed for a general audience and is not directed to
        children under the age of 16. GRIZZLEE does not knowingly collect or
        solicit personal information from children under the age of 16 through
        the Site. If we learn that we have collected personal information from a
        child under the age of 16, we will promptly delete that information from
        our records. If you believe that a child under the age of 16 may have
        provided us with personal information, please contact us as specified in
        the How to Contact Us section below.
      </p>
      <h3 id="updates">Updates to Our Privacy Policy</h3>
      <p>
        This Privacy Policy may be updated periodically to reflect changes in
        our personal information practices. We will indicate at the top of the
        notice when it was most recently updated.
      </p>
      <h3 id="contact">How to Contact Us</h3>
      <p>
        To update your preferences, update or correct your information, submit a
        request, ask us questions about this Privacy Policy, or exercise other
        applicable privacy rights, please contact us by email at{" "}
        <a href="mailto:privacy@begrizzlee.com">privacy@begrizzlee.com</a> or
        write to us at:
        <br />
        <br />
        <b class="underline">U.S:</b>
        <br />
        General Counsel
        <br />
        Seamount, Inc.
        <br />
        5353 Grosvenor Blvd
        <br />
        Los Angeles, CA 90066
        <br />
        <br />
        <br />
        <b class="underline">EEA:</b>
        <br />
        Residents of the EEA may contact us at:{" "}
        <a href="mailto:privacy@begrizzlee.com">privacy@begrizzlee.com</a>.
        <br />
        <br />
        If you are located in the EEA, the entity responsible for the processing
        of your personal information in the EEA is GRIZZLEE.
        <br />
      </p>
    </div>
  </Layout>
)

export default Privacy
